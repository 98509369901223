<template>
  <div class="about">
    <img :src="card" class="card" />
    <div class="content">
      <h3>{{ $t("about.company") }}</h3>
      <p>{{ $t("about.history") }}</p>
      <br />
      <h2>{{ $t("about.adm") }}</h2>
      <img class="president" :src="president" alt="president" width="65%" />
      <br />
      <p v-html="$t('about.msg')"></p>
    </div>
  </div>
</template>

<script>
import { storage } from "../../firebase.config";
import { getDownloadURL, ref } from "firebase/storage";
export default {
  data() {
    return {
      president: "",
      card: "",
    };
  },
  created() {
    getDownloadURL(ref(storage, "about/president.jpg")).then((url) => {
      this.president = url;
    });

    getDownloadURL(ref(storage, "about/head.jpg")).then((url) => {
      this.card = url;
    });
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,700;1,900&display=swap");

.card {
  padding: 0;
  border: none;
  width: 100%;
  border-radius: 0;
}
h1 {
  font-size: 80px;
  color: white;
}
.about {
  background-image: url("../assets/BG-full.png");
  background-size: 100%;
  /* background-attachment: fixed; */
  text-align: left;
  font-size: larger;
}
h2 {
  font-size: 45px;
}
h3 {
  font-size: 32px;
}
::v-deep em {
  font-size: x-large;
}
.content {
  padding: 20px;
}
@media screen and (min-width: 780px) {
  .president {
    padding: 30px;
  }
  .content {
    padding: 70px;
    padding-right: 150px;
  }
}
</style>
